import React from 'react';
import Link from 'next/link';
import colors from '../../../config/colors';

const getLinkStyles = (props) => {
  const borderWidth = props.outlineOnly ? 4 : 0;

  const { textColor } = props;

  const borderRadiuses = {
    sharp: 0,
    rounded: 4,
    pill: 25,
  };

  return `
    .button-link.promobtn:hover {
 		box-shadow: 0px 0px 5px 1px #FCCEA0!important; 
 		}

		.button-link {
      font-size: 12px;
			border-width: ${borderWidth}px;
			border-style: solid;
			padding-top: ${props.verticalPadding}px;
			padding-bottom: ${props.verticalPadding}px;
			padding-left: ${props.horizontalPadding}px;
			padding-right: ${props.horizontalPadding}px;
			border-radius: ${borderRadiuses[props.buttonCornerStyle]}px;
			position: relative;
			left: 0;
			top: 0;
			z-index: 0;
      display: inline-table;
			margin-top: 0;
		}

    @media (max-width: 1199px) {
      .button-link {
        padding-right: 15px !important;
        padding-left: 15px !important;
        text-wrap: nowrap;
      }
    }

		@media (max-width: 991px) {
			.button-link {
				margin-left: 0 !important;
				margin-bottom: 20px;
				margin-top: 10px;
				padding-top: 12px;
				padding-bottom: 12px;
        text-align: center;
        font-size: 12px;
			}
		}

		.button-link::before {
			content: '';
			position: absolute;
			width: 100%;
			width: calc(100% + 4px);
			height: 100%;
			height: calc(100% + 4px);
			bottom: -8px;
			left: -8px;
			background-color: transparent;
			z-index: -1;
			border-left: 4px solid #00B4C8;
			border-bottom: 4px solid #00B4C8;
			display: none;
		}
		.button-link:hover {
			text-decoration: none;

		}
	`;
};

const LINK_CLASS_NAME = 'button-link';

const ButtonLink = ({
  href,
  text,
  type = 'internal',
  styleOverrides = {},
  horizontalPadding,
  verticalPadding,
  buttonCornerStyle,
  highlightColor,
  textColor,
  outlineOnly,
  onClick,
  className,
}) => {
  const styles = getLinkStyles({
    outlineOnly,
    highlightColor,
    textColor,
    horizontalPadding,
    verticalPadding,
    buttonCornerStyle,
  });

  const backgroundColor = outlineOnly ? 'transparent' : highlightColor;
  const borderColor = outlineOnly ? highlightColor : 'transparent';

  const style = {
    backgroundColor,
    borderColor,
    color: textColor,
    ...styleOverrides,
  };

  if (type === 'external') {
    return (
      <>
        <a href={href} target="_blank" className={LINK_CLASS_NAME} style={style}>
          {text}
        </a>
        <style>{styles}</style>
      </>
    );
  }

  if (type === 'triggerModal') {
    return (
      <>
        <button onClick={() => onClick(true)} className={LINK_CLASS_NAME} style={style}>
          {text}
        </button>
        <style>{styles}</style>
      </>
    );
  }

  if (type === 'restart') {
    return (
      <>
        <button onClick={() => onClick()} className={`${LINK_CLASS_NAME} ${className}`} style={style}>
          {text}
        </button>
        <style>{styles}</style>
      </>
    );
  }

  return (
    <>
      <Link href={href}>
        <button className={`${LINK_CLASS_NAME} ${className}`} style={style}>
          {text}
        </button>
      </Link>
      <style>{styles}</style>
    </>
  );
};

ButtonLink.defaultProps = {
  highlightColor: colors.PRIMARY_BLUE,
  textColor: 'white',
  hasOutline: false,
  verticalPadding: 5,
  horizontalPadding: 25,
  buttonCornerStyle: 'pill',
  className: '',
};

export default ButtonLink;
