/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useEffect, useCallback, createContext } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import { ThemeProvider } from 'styled-components';

import AppErrorBoundary from '@components/system/errorBoundary';

import smoothscroll from 'smoothscroll-polyfill';
import SessionTimeoutAlert from '../components/common/miscellaneous/SessionTimeoutAlert';
import setupTimeoutResetsOnUserActivity from '../util/setupTimeoutResetsOnUserActivity';
import deactivateTimeoutCountdown from '../util/deactivateTimeoutCountdown';
import initializeTimeoutCountdown from '../util/initializeTimeoutCountdown';
import User from '../api/user';

import { redirectUser } from '../util/redirectUser';
import { dashboardPaths, deleteCookie, destroyClientCookie, getCookieFromClient } from '../util';
import 'react-phone-number-input/style.css';
import { theme } from '../styled/theme';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/base.css';
import useInitUser from 'hooks/useInitUser';
import ProfileProvider from 'context/profile/provider';
import { IS_CLIENT_MODE } from 'util/constants';
import useBugsnag from 'hooks/useBugsnag';
import useApiCheckout from 'hooks/useApiCheckout';
import { useIdleTimer } from 'react-idle-timer';

export const StoreContext = createContext(null);

function resetTimeout(params = {}) {
  const { showAlertTimeout, endSessionTimeout, showTimeoutAlert = () => {}, logout = () => {} } = params;

  clearTimeout(showAlertTimeout);
  clearTimeout(endSessionTimeout);
  showTimeoutAlert(false);

  initializeTimeoutCountdown({ showTimeoutAlert, logout });
}

const timeout = 300000 
const promptBeforeIdle = 30000 

function ExpressPayWebsite({ Component, pageProps }) {

  const router = useRouter();
  const timeoutsRef = useRef({ showAlertTimeout: undefined, endSessionTimeout: undefined });
  const [showTimeoutAlert, setShowTimeoutAlert] = useState(false);
  const [windowEventListeners, setWindowEventListeners] = useState([]);
  const eventListenerFunctionRef = useRef(() => {});
  const { showAlertTimeout, endSessionTimeout } = timeoutsRef.current || {};

  const [user_, setUser_] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
 
  const user = useInitUser([loggedIn]);

  const [authenticationRequired, setAuthenticationRequired] = useState(user ? false : true);
  const [authenticationCompleted, setAuthenticationCompleted] = useState(user ? true : false);
  const idleTimer = useRef(null);
  const [remaining, setRemaining] = useState(timeout /1e3);
  const [open, setOpen] = useState(false);


  // console.log('user_', user_);
  // console.log('loggedIn', loggedIn);
  // console.log('loading', loading);

  const onActive = () => {
    setOpen(false)
  }

  const onPrompt = () => {
    setOpen(true)
  }

  const { getRemainingTime, activate, pause, start  } = useIdleTimer({
    onIdle: () => {
      logout()
      pause()
      setOpen(false)
    },
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    startManually:true,
  })

  const handleStartTimer = () => {
    start(); // Start the timer manually
  };

  useEffect(() => {
    if (loggedIn) {
      handleStartTimer();
    }
  }, [loggedIn]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemaining(Math.ceil(getRemainingTime() / 1000))
  //   }, 500)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // })

  const handleStillHere = () => {
    activate()
  }

  const timeTillPrompt = Math.max(timeout - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  useEffect(() => storePathValues, [router.asPath]);

  function storePathValues() {
    const isClientSide = typeof window !== "undefined";
    if(isClientSide){
      const storage = window.sessionStorage; 
      if (!storage) return;
      // Set the previous path as the value of the current path.
      const prevPath = storage.getItem("currentPath");
      storage.setItem("prevPath", prevPath);
      // Set the current path value by looking at the browser's location object.
      storage.setItem("currentPath", window.location.pathname);
    }
  }
  
  const { isApiCheckout, guestAuthSuccessful } = useApiCheckout();


  const refreshAuth = useCallback(async (user, isApiCheckout, guestAuthSuccessful) => {
    console.log('refreshAuth is called,|| user || isApiCheckout || guestAuthSuccessful', user, isApiCheckout, guestAuthSuccessful);
    if (user) {
      setUser_(user);
      setLoggedIn(true);
    }else {
      try {
        setLoading(true);
        if (!isApiCheckout || (isApiCheckout && guestAuthSuccessful)) {
          console.log('hap 2');
          const userRes = await User.isLoggedIn();
          if (userRes) {
            setUser_(userRes);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        }
      } catch (err) {
        setLoggedIn(false);
      } finally {
        setLoading(false);
      }
    }
 
  }, [setUser_, setLoggedIn, setLoading]);

  useEffect(() => {
    const cookies = IS_CLIENT_MODE && getCookieFromClient("epay_usr");
    const userFromClient = cookies ? JSON.parse(cookies) : null;
    refreshAuth(userFromClient, isApiCheckout, guestAuthSuccessful);
  }, [refreshAuth, isApiCheckout, guestAuthSuccessful]);

  useEffect(() => {
    // initialize the smooth-scroll polyfill!
    smoothscroll.polyfill();

    // initialise Bugsnag
    // NOTE: this method needs to be called only once.
    // In the future where this useEffect gets a dependency state variable,
    // this initiation call must be moved to another useEffect without any dependencies
    // or its very own useEffect
    useBugsnag().start();

    if (user) {
      // eslint-disable-next-line no-use-before-define
      _initializeTimeoutCountdown();
    }
    const consoleLogWarningAndBuild = () => {
      // eslint-disable-next-line no-empty
      // eslint-disable-next-line no-undef
      if (typeof __NEXT_DATA__ === 'object') {
        console.info('%cStop!', 'color: #F76E1E; font-size: 30px; font-weight: bold;');
        console.info('%cDO NOT TYPE OR PASTE ANYTHING HERE', 'color: #F76E1E; font-size: 18px; font-weight: bold;');
        // eslint-disable-next-line no-undef
        console.info(`%cVERSION=${__NEXT_DATA__.buildId.slice(0, 8)}`, 'color: #047CDE; font-size: 14px;');
      }
    };

    consoleLogWarningAndBuild();
  }, []);

  // eslint-disable-next-line no-underscore-dangle
  const _deactivateTimeoutCountdown = () => {
    deactivateTimeoutCountdown({ showAlertTimeout, endSessionTimeout });
    windowEventListeners.forEach((each) => {
      document.removeEventListener(each, resetTimeout);
    });
  };

  const logout = async (callback) => {
    try {
      await User.logout();
      setLoggedIn(false);
      setUser_(null);
      setAuthenticationRequired(true);
      setAuthenticationCompleted(false);
      deleteCookie("epay_usr")
      destroyClientCookie('ep_usr');
      destroyClientCookie('PHPSESSID');
      setShowTimeoutAlert(false);
      _deactivateTimeoutCountdown();
      if (typeof callback === 'function') {
        callback();
      } else {
        router.push('/');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleAuthSuccess = (user, url) => {
    console.log('handleAuthSuccessCalled');
    setLoggedIn(true);
    setAuthenticationRequired(false);
    setAuthenticationCompleted(true);
    if (url === 'none') return;
    if (!url) {
      return router.push(url);
    }
    router.push('/');
  };

  const globalProps = {
    logout,
    handleAuthSuccess,
  };

  const _setupTimeoutResetsOnUserActivity = () => {
    setupTimeoutResetsOnUserActivity({
      showAlertTimeout: timeoutsRef.current.showAlertTimeout,
      endSessionTimeout: timeoutsRef.current.endSessionTimeout,
      showTimeoutAlert: setShowTimeoutAlert,
      logout,
      resetTimeout,
      setWindowEventListeners,
      setEventListenerFunctionRef: (fn) => (eventListenerFunctionRef.current = fn),
    });
  };

  const _initializeTimeoutCountdown = () => {
    initializeTimeoutCountdown({
      showTimeoutAlert: setShowTimeoutAlert,
      logout,
      setShowAlertTimeout: (timeout) => {
        timeoutsRef.current.showAlertTimeout = timeout;
      },
      setEndSessionTimeout: (timeout) => {
        timeoutsRef.current.endSessionTimeout = timeout;
      },
    });
    _setupTimeoutResetsOnUserActivity();
  };

  return (
    <>
      <Head>
        <title>expressPay</title>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
      </Head>
      <AppErrorBoundary>
        <StoreContext.Provider
          value={{
            showAlertTimeout,
            endSessionTimeout,
            setWindowEventListeners,
            windowEventListeners,
            showTimeoutAlert: setShowTimeoutAlert,
            initializeTimeoutCountdown: _initializeTimeoutCountdown,
            deactivateTimeoutCountdown: _deactivateTimeoutCountdown,
            setupTimeoutResetsOnUserActivity: _setupTimeoutResetsOnUserActivity,
            logout,
            isApiCheckout,
            auth: {
              authenticationRequired,
              setAuthenticationRequired,
              authenticationCompleted,
              setAuthenticationCompleted,
            },
            auth_: {
              user: user_,
              loggedIn,
              loading,
              refreshAuth,
              guestAuthSuccessful,
            },
          }}
        >
          <ProfileProvider>
            <ThemeProvider theme={theme}>
            {open ? 
              <SessionTimeoutAlert
                onClick={() => {
                  handleStillHere()
       
                }}
                remaining={remaining}
              /> : null}

              <Component user={user_} {...pageProps} {...globalProps} handleLogout={logout} />
            </ThemeProvider>
          </ProfileProvider>
        </StoreContext.Provider>
      </AppErrorBoundary>
    </>
  );
}

ExpressPayWebsite.getInitialProps = async ({ Component, ctx }) => {
  // console.log("ctx", ctx);
  const cookies = parseCookies(ctx);
  // console.log("cookies", cookies);

  let pageProps = {};
  const { ep_usr: epUsr } = cookies;

  //Adding this to prevent ctx errors because of static generation which does not produce res and req on context
  ctx.res = { writeHead: () => {}, end: () => {} };

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  if (!epUsr) {
    const dashboardUrls = Object.values(dashboardPaths).flat();
    const isProtectedRoute = dashboardUrls.includes(ctx.pathname);
    if (isProtectedRoute) {
      redirectUser(ctx, '/login');
    }
  }

  return {
    pageProps,
  };
};

function CustWithRouter({ props }) {
  const pr = { ...props };
  pr.router = useRouter();
  return <ExpressPayWebsite {...pr} />;
}
// export default CustWithRouter;
export default ExpressPayWebsite;
